import { useLocation } from 'react-router'
import dmente from '../../assets/dmente_black_background.png'
import dmenteWhite from '../../assets/dmente_yellow_background.png'
import style from './styles.module.css'

function Header({ black }){
    const location = useLocation()
    const localBlack = black ? black : location.pathname === "/" ? true : false
    const borderStyle = localBlack ? style.border : style.borderBlack
    return (
        <>
            <header className={borderStyle + " " + (localBlack ? style.containerBlack : style.container)}>
                <img className="img-fluid" src={localBlack ? dmente : dmenteWhite} alt="D-mente App" />
            </header>
        </>
    )
}

export default Header
import { useState } from 'react'
import { useHistory } from 'react-router'
import ContainerPage from '../../components/ContainerPage'

import { useData } from '../../Context/DataContext'

function ExameFisico(){
    const data = useData()
    const [exameFisico, setExameFisico] = useState({
        pressao: '',
        dificuldadeMarcha: false,
        informaEstadoAtual: false,
        conscienteDeficit: false,
        meningismo: false,
        papiledema: false,
        disturbioSensitivo: false,
        avc: false,
        alteracaoComportamental: false,
        pensamentoPerseverativo: false,
    })
    const history = useHistory()

    const handleInput = (evt) => {
        const field = evt.target.name
        const value = evt.target.value

        setExameFisico({
            ...exameFisico,
            [field]: value
        })
    }

    const next = (e) => {
        // data.setData({
        //     ...data.data,
        //     exameFisico: {
        //         ...data.exameFisico,
        //         pressao: exameFisico.pressao,
        //         dificuldadeMarcha: exameFisico.dificuldadeMarcha,
        //         informaEstadoAtual: exameFisico.informaEstadoAtual,
        //         conscienteDeficit: exameFisico.conscienteDeficit,
        //         meningismo: exameFisico.meningismo,
        //         papiledema: exameFisico.papiledema,
        //         disturbioSensitivo: exameFisico.disturbioSensitivo,
        //         avc: exameFisico.avc,
        //         alteracaoComportamental: exameFisico.alteracaoComportamental,
        //         pensamentoPerseverativo: exameFisico.pensamentoPerseverativo,
        //     }
        // })

        const newData = {
            ...(data.data),
            exameFisico: {
                ...exameFisico
            }
        }

        data.setData(newData)

        history.push('/minimental')
    }
// console.log(data.data)
    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Exame Físico do Paciente</strong></h3>

                    <form>
                        <div className="form-group">
                            <label>Pressão arterial</label>
                            <input
                                type="text"
                                className="form-control"
                                name="pa"
                                value={exameFisico.pa}
                                onChange={handleInput} />
                        </div>
                        <div className="form-group">
                            <label>Tem dificuldade de marcha?</label>
                            <select 
                                className="form-control" 
                                value={exameFisico.dificuldadeMarcha} 
                                onChange={handleInput} 
                                name="dificuldadeMarcha">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Informa bem  sobre o estado  atual?</label>
                            <select 
                                className="form-control" 
                                value={exameFisico.informaEstadoAtual} 
                                onChange={handleInput} 
                                name="informaEstadoAtual">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Tem consciência do deficit de memória?</label>
                            <select 
                                className="form-control" 
                                value={exameFisico.conscienteDeficit} 
                                onChange={handleInput} 
                                name="conscienteDeficit">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Tem meningismo?</label>
                            <select 
                                className="form-control" 
                                value={exameFisico.meningismo} 
                                onChange={handleInput} 
                                name="meningismo">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Tem papiledema?</label>
                            <select 
                                className="form-control" 
                                value={exameFisico.papiledema} 
                                onChange={handleInput} 
                                name="papiledema">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Tem disturbio sensitivo?</label>
                            <select 
                                className="form-control" 
                                value={exameFisico.disturbioSensitivo} 
                                onChange={handleInput} 
                                name="disturbioSensitivo">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Passado de Acidente Vascular Encefálico?</label>
                            <select 
                                className="form-control" 
                                value={exameFisico.avc} 
                                onChange={handleInput} 
                                name="avc">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Tem alteração comportamental?</label>
                            <select 
                                className="form-control" 
                                value={exameFisico.alteracaoComportamental} 
                                onChange={handleInput} 
                                name="alteracaoComportamental">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Tem pensamento perseverativo?</label>
                            <select 
                                className="form-control" 
                                value={exameFisico.pensamentoPerseverativo} 
                                onChange={handleInput} 
                                name="pensamentoPerseverativo">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Próximo</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default ExameFisico
import { useState } from 'react'
import ContainerPage from '../../components/ContainerPage'

import DatePicker from 'react-datepicker'
import { useData } from '../../Context/DataContext'
import { useHistory } from 'react-router-dom'

function Home(){
    const data = useData()
    const [paciente, setPaciente] = useState({
        nome: '',
        nascimento: '',
        cpf: '',
        escolaridade: ''
    })
    const history = useHistory()

    const handleInput = (evt) => {
        const field = evt.target.name
        const value = evt.target.value

        setPaciente({
            ...paciente,
            [field]: value
        })
    }

    const next = (e) => {
        // data.setData({
        //     ...data.data,
        //     paciente: {
        //         ...data.paciente,
        //         nome: paciente.nome,
        //         nascimento: paciente.nascimento,
        //         cpf: paciente.cpf,
        //         escolaridade: paciente.escolaridade
        //     }
        // })

        const regex = /\d\d\/\d\d\/\d\d\d\d/
        if(!regex.test(paciente.nascimento)){
            alert('Você deve informar uma data válida no formato: dd/mm/yyyy')
            return
        }

        const pacient = {
            ...data.paciente,
            ...paciente
        }

        const newData = {
            ...(data.data),
            paciente: {
                ...pacient
            }
        }

        data.setData(newData)

        // console.log(data)

        history.push('/anamnese')
    }

    // console.log(data)

    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Dados do paciente</strong></h3>

                    <form>
                        <div className="form-group">
                            <label>Nome</label>
                            <input
                                type="text"
                                className="form-control"
                                name="nome"
                                value={paciente.nome}
                                onChange={handleInput} />
                        </div>
                        <div className="form-group">
                            <label>Data de Nascimento</label>

                            <input 
                                type='text'
                                name='nascimento'
                                className='form-control'
                                value={paciente.nascimento}
                                onChange={(data) => {
                                    setPaciente({ ...paciente, nascimento: data.target.value})
                                    
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label>CPF</label>
                            <input
                                type="text"
                                name="cpf"
                                className="form-control"
                                value={paciente.cpf}
                                onChange={handleInput} />
                        </div>
                        <div className="form-group">
                            <label>Quantos anos o paciente estudou?</label>
                            <input 
                                type="number"
                                name="escolaridade"
                                className="form-control"
                                value={paciente.escolaridade}
                                onChange={handleInput} />
                        </div>
                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Próximo</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default Home

import Header from './components/Header'
import Home from './pages/Home'
import Paciente from './pages/Paciente'
import Anamnese from './pages/Anamnese'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import { DataProvider } from './Context/DataContext'
import Iqcode from './pages/Iqcode'
import ExameFisico from './pages/ExameFisico'
import MiniMental from './pages/MiniMental'
import NomeacaoFiguras from './pages/NomeacaoFiguras'
import Nomeacao2 from './pages/NomeacaoFiguras/Nomeacao2'
import Nomeacao3 from './pages/NomeacaoFiguras/Nomeacao3'
import Relogio from './pages/Relogio'
import Memorizacao from './pages/Memorizacao'
import Exames from './pages/Exames'
import ResultadoFinal from './pages/ResultadoFinal'
import Katz from './pages/Katz'
import FluenciaVerbal from './pages/FluenciaVerbal'
import Apresentacao from './pages/Apresentacao'
import Bibliografia from './pages/Bibliografia'


function App() {
  return (
    <DataProvider>
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact>
            <Home/>
          </Route>
          <Route path="/init" exact>
            <Paciente/>
          </Route>
          <Route path="/anamnese" exact>
            <Anamnese/>
          </Route>
          <Route path="/iqcode" exact>
            <Iqcode/>
          </Route>
          <Route path="/exameFisico" exact>
            <ExameFisico/>
          </Route>
          <Route path="/minimental" exact>
            <MiniMental/>
          </Route>
          <Route path="/nomeacaoFiguras" exact>
            <NomeacaoFiguras/>
          </Route>
          <Route path="/relogio" exact>
            <Relogio/>
          </Route>
          <Route path="/memorizacao" exact>
            <Memorizacao/>
          </Route>
          <Route path="/exames" exact>
            <Exames/>
          </Route>
          <Route path="/resultadoFinal" exact>
            <ResultadoFinal/>
          </Route>
          <Route path="/katz" exact>
            <Katz/>
          </Route>
          <Route path="/nomeacao2" exact>
            <Nomeacao2/>
          </Route>
          <Route path="/nomeacao3" exact>
            <Nomeacao3/>
          </Route>
          <Route path="/fluenciaVerbal" exact>
            <FluenciaVerbal/>
          </Route>
          <Route path="/apresentacao" exact>
            <Apresentacao />
          </Route>
          <Route path="/biblio" exact>
            <Bibliografia />
          </Route>
        </Switch>
      </Router>
    </DataProvider>
  )
}

export default App


import ContainerPage from '../../components/ContainerPage'

import { useHistory } from 'react-router-dom'
import img3 from '../../assets/img3.jpeg'
import { useState } from 'react'
import { useData } from '../../Context/DataContext'

function Memorizacao(){
    const history = useHistory()
    const [dados, setDados] = useState({
        memoFiguras: 0
    })

    const data = useData()

    const next = (e) => {
        // data.setData({
        //     ...data,
        //     memoFiguras: dados.memoFiguras
        // })

        const newData = {
            ...(data.data),
            memoFiguras: dados.memoFiguras
        }

        data.setData(newData)

        history.push('/exames')
    }

    const handleChange = (event) => {
        setDados({
            ...dados,
            [event.target.name]: event.target.value
        })
    }


    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Memorização de imagens</strong></h3>

                    <form>
                        <p>
                            Aqui estão as figuras que eu lhe mostrei hoje e outras figuras novas. Quero que você me diga quais você já tinha visto há alguns minutos.
                        </p>
                        <div className='text-center'>
                            <img src={img3} className='img-fluid' alt='figuras'/>   
                        </div>
                        <p>
                            Itens inseridos erroneamente devem ser descontados de modo que se lembrar de 8 itens corretos e incluir um item errado, seu escore será 8 (acertos) menos 1 (erro) = 7
                        </p>

                        <input type={"number"} className="form-control" name='memoFiguras' onChange={handleChange} value={dados.memoFiguras} />
                        
                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Próximo</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default Memorizacao
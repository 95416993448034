
import ContainerPage from '../../components/ContainerPage'

import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useData } from '../../Context/DataContext'

function Relogio(){
    const history = useHistory()

    const [dados, setDados] = useState({
        relogio: 0
    })

    const data = useData()

    const next = (e) => {

        // data.setData({
        //     ...data,
        //     relogio: dados.relogio
        // })

        const newData = {
            ...(data.data),
            relogio: dados.relogio
        }

        data.setData(newData)

        history.push('/memorizacao')
    }

    const handleChange = (event) => {
        setDados({
            ...dados,
            [event.target.name]: event.target.value
        })
    }

    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Teste do relógio</strong></h3>

                    <form>
                        <p>
                            Desenhe um relógio com todos os números. Coloque ponteiros marcando 02h45 (Guarde o desenho com a ficha)
                        </p>

                        <h3>Resultado</h3>

                        <ul>

                            <li>10 - Hora certa</li>
                            <li>9 - Leve distúrbio nos ponteiros</li>
                            <li>8 - Distúrbios mais intensos nos ponteiros</li>
                            <li>7 - Ponteiros completamente errados</li>
                            <li>6 - Uso inapropriado</li>
                            <li>5 - Números em ordem inversa ou concentrados em alguma parte do relógio</li>
                            <li>4 - Números faltando ou situados fora dos limites do relógio</li>
                            <li>3 - Números e relógio não mais conectados. Ausência dos ponteiros</li>
                            <li>2 - Alguma evidência de ter entendido as instruções, mas com vaga semelhança com um relógio</li>
                            <li>1 - Não tentou ou não conseguiu representar um relógio</li>
                        </ul>

                        <input type={"number"} className="form-control" name='relogio' onChange={handleChange} value={dados.relogio} />

                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Próximo</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default Relogio
import { createContext, useContext, useState } from 'react'

export const DataContext = createContext({ paciente: {} })

export const DataProvider = ({ children }) => {
    const [data, setData] = useState({
        paciente: {}
    })

    return (
        <DataContext.Provider value={ {data, setData} }>
            { children }
        </DataContext.Provider>
    )
}

export const useData = () => {
    const data = useContext(DataContext)
    return data
}
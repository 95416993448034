
import ContainerPage from '../../components/ContainerPage'

import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useData } from '../../Context/DataContext'

import img1 from '../../assets/img1.png'

function MiniMental(){
    const history = useHistory()
    const [dados, setDados] = useState({
        ano: 0,
        mes: 0,
        diaMes: 0,
        diaSemana: 0,
        hora: 0,
        local: 0,
        bairro: 0,
        cidade: 0,
        estado: 0,
        pais: 0,
        pontos3: 0,
        pontos4: 0,
        pontos5: 0,
        pontos6: 0,
        pontos7: 0,
        pontos8: 0,
        pontos9: 0,
        pontos10: 0,
        pontos11: 0,
    })

    const data = useData()

    const next = (e) => {

        let pontos = 0

        Object.keys(dados).forEach(key => {
            pontos += parseInt(dados[key])
        })

        // data.setData({
        //     ...data,
        //     minimental: pontos
        // })

        const newData = {
            ...(data.data),
            minimental: pontos
        }

        data.setData(newData)

        history.push('/nomeacaoFiguras')
    }

    const handleChange = (event) => {
        setDados({
            ...dados,
            [event.target.name]: event.target.value
        })
    }

    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Mini Exame Estado Mental (MEEM)</strong></h3>

                    <form>
                        <p className='mt-4'>
                            ORIENTAÇÃO (1 PONTO PARA CADA RESPOSTA CERTA)
                        </p>

                        <p className='mt-4'>
                            Qual ano estamos?<br />
                            <input type="number" className="form-control" name='ano' value={dados.ano} onChange={handleChange} /><br />
                            
                            Mês?<br />
                            <input type="number" className="form-control" name='mes' value={dados.mes} onChange={handleChange} /><br />
                            
                            Dia do mês?<br />
                            <input type="number" className="form-control" name='diaMes' value={dados.diaMes} onChange={handleChange} /><br />
                            
                            Dia da semana?<br />
                            <input type="number" className="form-control" name='diaSemana' value={dados.diaSemana} onChange={handleChange} /><br />

                            Qual hora aproximadament é agora?<br />
                            <input type="number" className="form-control" name='hora' value={dados.hora} onChange={handleChange} /><br />
                        </p>

                        <p className='mt-4'>
                            Qual local nós estamos? (consultório, loja, igreja)<br />
                            <input type="number" className="form-control" name='local' value={dados.local} onChange={handleChange} /><br />

                            Qual bairro nós estamos?<br />
                            <input type="number" className="form-control" name='bairro' value={dados.bairro} onChange={handleChange} /><br />

                            Qual cidade nós estamos?<br />
                            <input type="number" className="form-control" name='cidade' value={dados.cidade} onChange={handleChange} /><br />

                            Em qual estado esta cidade fica?<br />
                            <input type="number" className="form-control" name='estado' value={dados.estado} onChange={handleChange} /><br />

                            Em que país?<br />
                            <input type="number" className="form-control" name='pais' value={dados.pais} onChange={handleChange} /><br />
                        </p>

                        <p className='mt-4'>
                            <h4 className='text-center'>Registro</h4>
                            Dizer três palavras: PENTE; RUA; AZUL. Pedir para prestar atenção porque terá de repetir mais tarde. Pergunte pelas palavras logo após tê-las nomeado. Repetir até 5 vezes até que tenha aprendido as palavras. O score desta fase será dado pelo numero de acertos na primeira tentativa apenas. (variação de 1 a 3)<br/>
                            <input type={"number"} className="form-control" name='pontos3' value={dados.pontos3} onChange={handleChange} />
                        </p>

                        <p className='mt-4'>
                            <h4 className='text-center'>ATENÇÃO E CALCULO</h4>
                            SUBTRAIR DE 100 – 7. SEQUENCIA DE 5 SUBTRAÇÕES. 1 PONTO PARA CADA SUBTRAÇÃO. SE NÃO CONSEGUIR NA SEGUNDA TENTATIVA, SOLICITAR QUE SOLETRE A PALAVRA MUNDO DE TRÁS PARA FRENTE.<br/>
                            <input type={"number"} className="form-control" name='pontos4' value={dados.pontos4} onChange={handleChange} />
                        </p>

                        <p className='mt-4'>
                            <h4 className='text-center'>EVOCAÇÃO</h4>
                            PERGUNTAR PELAS TRÊS PALAVRAS ANTERIORES (PENTE; RUA E AZUL). 1 PONTO PARA CADA RESPOSTA CERTA<br/>
                            <input type={"number"} className="form-control" name='pontos5' value={dados.pontos5} onChange={handleChange} />
                        </p>

                        LINGUAGEM

                        <p>
                            NOMEAR 02 OBJETOS (POR EXEMPLO LAPIS E PAPEL). Pontuar 1 ponto para cada objeto correto (vale 2 pontos)<br/>
                            <input type={"number"} className='form-control' name='pontos6' value={dados.pontos6} onChange={handleChange} />
                        </p>
                        <p>
                            REPETIR: “NEM AQUI, NEM ALI, NEM LÁ (1 PONTO PARA CERTO)<br/>
                            <input type={"number"} className='form-control' name='pontos7' value={dados.pontos7} onChange={handleChange} />
                        </p>
                        <p>
                            SEGUIR COMANDO DE TRÊS ESTAGIOS: “PEGUE O PAPEL COM A MÃO DIREITA, DOBRE-O AO MEIO E COLOQUE NO CHÃO (PONTUAR CADA COMANDO EXECUTADO COM 1 PONTO: VALE TOTAL DE 3 PONTOS)<br/>
                            <input type={"number"} className='form-control' name='pontos8' value={dados.pontos8} onChange={handleChange} />
                        </p>
                        <p>
                            LER EM VOZ BAIXA E EXECUTAR: “FECHE OS OLHOS” (VALE 01 PONTO CADA)<br/>
                            <input type={"number"} className='form-control' name='pontos9' value={dados.pontos9} onChange={handleChange} />
                        </p>
                        <p>
                            ESCREVER UMA FRASE SIMPLES ( SUGESTÃO: “A CASA É BONITA”). (VALE 01 PONTO CADA)<br/>
                            <input type={"number"} className='form-control' name='pontos10' value={dados.pontos10} onChange={handleChange} />
                        </p>
                        <p>
                            COPIAR O DESENHO (VALE 01 PONTO CADA)<br/>
                            <input type={"number"} className='form-control' name='pontos11' value={dados.pontos11} onChange={handleChange} />
                        </p>
                            
                        <img className='img-fluid' src={img1} alt="Doi hexagramas" />

                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Próximo</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default MiniMental
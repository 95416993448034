
import ContainerPage from '../../components/ContainerPage'

import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useData } from '../../Context/DataContext'

import img2 from '../../assets/img2.jpeg'

function Nomeacao3(){
    const history = useHistory()
    const [dados, setDados] = useState({
        quantidadeMemoria3: 0
    })

    const handleChange = (event) => {
        setDados({
            ...dados,
            [event.target.name]: event.target.value
        })
    }

    const data = useData()

    const next = (e) => {

        // data.setData({
        //     ...data,
        //     quantidadeMemoria3: dados.quantidadeMemoria3,
        // })

        const newData = {
            ...(data.data),
            ...dados
        }

        data.setData(newData)

        history.push('/fluenciaVerbal')
    }

    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Identificação e Nomeação de Figuras</strong></h3>

                    <form>
                        
                        <p>Ao terminar, mostre novamente a figura ao examinado e diga:</p>
                        <p>Olhe bem e procure memorizar estas figuras. O tempo máximo permitido é de 30 segundos. Novamente, esconda a folha e pergunte:</p>

                        <img className='img-fluid' src={img2} alt='Nitrini' />
                        
                        <p>Que figuras eu acabei de lhe mostrar?</p>
                        <p><small>O número de itens evocados fornece o escore de memória imediata</small></p>
                        <input type={"number"} className="form-control" name='quantidadememoria3' onChange={handleChange} value={dados.quantidadememoria3} />

                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Próximo</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default Nomeacao3
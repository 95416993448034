
import ContainerPage from '../../components/ContainerPage'

import { useHistory } from 'react-router-dom'
import img2 from '../../assets/img2.jpeg'
import { useState } from 'react'
import { useData } from '../../Context/DataContext'

function NomeacaoFiguras(){
    const history = useHistory()
    const [dados, setDados] = useState({
        quantidadeFiguras: 0
    })

    const handleChange = (event) => {
        setDados({
            ...dados,
            [event.target.name]: event.target.value
        })
    }

    const data = useData()

    const next = (e) => {

        // data.setData({
        //     ...data,
        //     NomeacaoFiguras: dados.quantidadeFiguras
        // })

        const newData = {
            ...(data.data),
            NomeacaoFiguras: dados.quantidadeFiguras
        }

        data.setData(newData)

        history.push('/nomeacao2')
    }

    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Identificação e Nomeação de Figuras</strong></h3>

                    <form>
                        <img className='img-fluid' src={img2} alt='Nitrini' />
                        <p>
                            Apresente as figuras desenhadas e pergunte:
                        </p>

                        <ul>
                            <li>Que figuras são?</li>
                            <li>Nomeação correta?</li>
                            <li>Percepção correta?</li>
                        </ul>

                        <p>
                            Informe a quantidade de acertos:<br/>
                            <input type={"number"} className="form-control" name='quantidadeFiguras' onChange={handleChange} value={dados.quantidadeFiguras} />
                        </p>

                        <p>
                            Se não for capaz de perceber adequadamente um ou dois itens ou de nomeá-los  não corrija. Aceite o nome que o paciente deu e considere-os corretos na avaliação da memória.
                        </p>

                        
                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Próximo</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default NomeacaoFiguras
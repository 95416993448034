import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import ContainerPage from '../../components/ContainerPage'

import { useData } from '../../Context/DataContext'

function Katz(){
    const data = useData()
    const [katz, setKatz] = useState({
        respostas: []
    })

    const questoes = [
        {
            id: 1,
            titulo: "Banho",
            description: "Não recebe ajuda ou somente recebe ajuda para 01 parte do corpo"
        },
        {
            id: 2,
            titulo: "Vestir-se",
            description: "Pega as roupas e se veste sem qualquer ajuda, exceto para amarrar os sapatos"
        },
        {
            id: 3,
            titulo: "Higiene Pessoal",
            description: "Vai ao banheiro, usa o banheiro, veste-se e retorna sem qualquer ajuda (pode usar andador ou bengala)"
        },
        {
            id: 4,
            titulo: "Transferência",
            description: "Consegue deitar na cama, sentar na cadeira e levantar sem ajuda (pode usar andador ou bengala)"
        },
        {
            id: 5,
            titulo: "Continência",
            description: "Controla completamente urina e fezes"
        },
        {
            id: 6,
            titulo: "Alimentação",
            description: "Come sem ajuda (exceto para cortar carne ou passar manteiga no pão)"
        },
    ]
    const history = useHistory()

    useEffect(() => {
        const respostas = {}
        questoes.forEach(questao => {
            respostas[questao.id] = 1
        })

        setKatz({
            respostas
        })
    }, [])

    const handleInput = (evt) => {
        const field = evt.target.name
        const value = evt.target.value

        setKatz({
            ...katz,
            respostas: {
                ...katz.respostas,
                [field]: value
            }
        })
    }

    const next = (e) => {
        const keys = Object.keys(katz.respostas)
        let score = 0

        keys.forEach(key => {
            score += parseInt(katz.respostas[key])
        })

        // data.setData({
        //     ...data.data,
        //     katz: score
        // })

        const newData = {
            ...(data.data),
            katz: score
        }

        data.setData(newData)

        history.push("exameFisico")
    }
    console.log(katz)

    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Escala KATZ</strong></h3>

                    <p>
                        ESCALA PARA AVALIAÇÃO DAS ATIVIDADES DE VIDA DIÁRIA BASICA
                    </p>

                    <p>
                        Para cada área de função a ser testada, cheque a descrição que melhor se adapta. O termo “AJUDA” utilizado tem a conotação de supervisão ou assistência direta de pessoas. Entrevistar o(a) paciente e, em seguida, confirmar com o acompanhante.
                    </p>

                    <form>
                        {
                            questoes.map(questao => (
                                <div className="form-group mt-3 mb-3" key={questao.id}>
                                    <label>{questao.id} - {questao.titulo}<br /> {questao.description}</label>
                                    <select 
                                        className="form-control" 
                                        value={katz.dificuldadeCoisas} 
                                        onChange={handleInput} 
                                        name={questao.id}>
                                        <option value={1}>Sim</option>
                                        <option value={0}>Não</option>
                                    </select>
                                </div>
                            ))
                        }
                        
                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Próximo</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default Katz
import { useState } from 'react'
import { useHistory } from 'react-router'
import ContainerPage from '../../components/ContainerPage'

import { useData } from '../../Context/DataContext'

function Anamnese(){
    const data = useData()
    const [anamnese, setAnamnese] = useState({
        queixa: '',
        dificuldadeCoisas: false,
        dificuldadeNomes: false,
        dificuldadeDatas: false,
        dinheiro: false,
        atividades: false,
        sono: '',
        dificuldadeFamilia: false,
        consaguinidade: false,
        doencas: '',
        medicacao: '',
    })
    const history = useHistory()

    const handleInput = (evt) => {
        const field = evt.target.name
        const value = evt.target.value

        setAnamnese({
            ...anamnese,
            [field]: value
        })
    }

    const next = (e) => {
        // data.setData({
        //     ...data.data,
        //     anamnese: {
        //         ...data.anamnese,
        //         queixa: anamnese.queixa,
        //         dificuldadeCoisas: anamnese.dificuldadeCoisas,
        //         dificuldadeNomes: anamnese.dificuldadeNomes,
        //         dificuldadeDatas: anamnese.dificuldadeDatas,
        //         dinheiro: anamnese.dinheiro,
        //         atividades: anamnese.atividades,
        //         sono: anamnese.sono,
        //         dificuldadeFamilia: anamnese.dificuldadeFamilia,
        //         consaguinidade: anamnese.consaguinidade,
        //         doencas: anamnese.doencas,
        //         medicacao: anamnese.medicacao,
        //     }
        // })

        const newData = {
            ...(data.data),
            anamnese: {
                ...anamnese
            }
        }

        data.setData(newData)

        // console.log(data)

        history.push('/iqcode')
    }
// console.log(data) 
    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Anamnese do Paciente</strong></h3>

                    <form>
                        <div className="form-group">
                            <label>Queixa Principal</label>
                            <input
                                type="text"
                                className="form-control"
                                name="queixa"
                                value={anamnese.queixa}
                                onChange={handleInput} />
                        </div>
                        <div className="form-group">
                            <label>Tem dificuldade de lembrar das coisas?</label>
                            <select 
                                className="form-control" 
                                value={anamnese.dificuldadeCoisas} 
                                onChange={handleInput} 
                                name="dificuldadeCoisas">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Tem dificuldade de lembrar de nomes das pessoas?</label>
                            <select 
                                className="form-control" 
                                value={anamnese.dificuldadeNomes} 
                                onChange={handleInput} 
                                name="dificuldadeNomes">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Tem dificuldade de lembrar datas como aniversário?</label>
                            <select 
                                className="form-control" 
                                value={anamnese.dificuldadeDatas} 
                                onChange={handleInput} 
                                name="dificuldadeDatas">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Lida com dinheiro?</label>
                            <select 
                                className="form-control" 
                                value={anamnese.dinheiro} 
                                onChange={handleInput} 
                                name="dinheiro">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Realiza atividades do dia-a-dia (cozinhar, limpar, etc)?</label>
                            <select 
                                className="form-control" 
                                value={anamnese.atividades} 
                                onChange={handleInput} 
                                name="atividades">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Como está seu sono?</label>
                            <select 
                                className="form-control" 
                                value={anamnese.sono} 
                                onChange={handleInput} 
                                name="sono">
                                <option value={"Ruim"}>Ruim</option>
                                <option value={"Bom"}>Bom</option>
                                <option value={"Regular"}>Regular</option>
                                <option value={"Ótimo"}>Ótimo</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Alguém da família tinha ou tem dificuldade de memória?</label>
                            <select 
                                className="form-control" 
                                value={anamnese.dificuldadeFamilia} 
                                onChange={handleInput} 
                                name="dificuldadeFamilia">
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Quais doenças o Sr. Possui?</label>
                            <input
                                type="text"
                                className="form-control"
                                name="doencas"
                                value={anamnese.doencas}
                                onChange={handleInput} />
                        </div>
                        <div className="form-group">
                            <label>Faz uso de medicações? quais?</label>
                            <input
                                type="text"
                                className="form-control"
                                name="medicacao"
                                value={anamnese.medicacao}
                                onChange={handleInput} />
                        </div>
                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Próximo</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default Anamnese
import { useHistory } from 'react-router'
import mpcas from '../../assets/MPCAS_Univas.png'
import ContainerPage from '../../components/ContainerPage'

function Bibliografia(){
    const history = useHistory()
    const goToInit = () => history.push("/")
    return (
        <ContainerPage>
            <div className="row justify-content-center">
                <div className="col-10">
                    <img className="img-fluid" alt="MPCAS | Univás" src={mpcas}/>
                </div>
            </div>
            <div className="row">
                <div className="col mt-5 mb-5">
                    <p>1.Arevalo-Rodriguez I, Smailagic N, Roqué i Figuls M, Ciapponi A, Sanchez-Perez E, Giannakou A, Pedraza OL, Bonfill Cosp X, Cullum S. Mini-Mental State Examination (MMSE) for the detection of Alzheimer&#39;s disease and other dementias in people with mild cognitive impairment (MCI). Cochrane Database of Systematic Reviews 2015, Issue 3. Art. No.: CD010783. DOI: 10.1002/14651858.CD010783.pub2.</p>
                    <p>2. Arvanitakis Z, Shah RC, Bennett DA. Diagnóstico e Gestão da Demência: Revisão. JAMA. 22 de outubro de 2019(16):1589-1599. doi: 10.1001/jama.2019.4782. PMID: 31638686; PMCID: PMC7462122.</p>
                    <p>3. Burton JK, Stott DJ, McShane R, Noel-Storr AH, Swann-Price RS, Quinn TJ. Informant Questionnaire on Cognitive Decline in the Elderly (IQCODE) for the early detection of dementia across a variety of healthcare settings. Cochrane Database of Systematic Reviews 2021, Issue 7. Art. No.: CD011333. DOI: 10.1002/14651858.CD011333.pub3</p>
                    <p>4. Calf AH, Pouw MA, van Munster BC, Burgerhof JGM, de Rooij SE, Smidt N. Screening instruments for cognitive impairment in older patients in the Emergency Department: a systematic review and meta-analysis. Age Ageing. 2021 Jan 8;50(1):105-112. doi: 10.1093/ageing/afaa183. PMID: 33009909; PMCID: PMC7793600. </p>
                    <p>5. Caramelli P, Teixeira A L, Buchpiguel C A, Lee H W, Livramento J A, Fernandez L L, Anguinah R. “Diagnostico de doença de Alzheimer no Brasil. Dement Neuropsychol, 2011, June; 5(suppl1);11-20. </p>
                    <p>6. Chaves, Márcia L.F.  Godinho, Claudia C.  Porto, Claudia S.  Mansur, Leticia   Carthery- Goulart, Maria Teresa  ;  Yassuda, Mônica S. ; Beato R. Dementia &amp; Neuropsychologia Set 2011, Volume 5 Nº 3 Páginas 153 – 166</p>
                    <p>7. Creavin ST, Wisniewski S, Noel-Storr AH, Trevelyan CM, Hampton T, Rayment D, Thom VM, Nash KJE, Elhamoui H, Milligan R, Patel AS, Tsivos DV, Wing T, Phillips E, Kellman SM, Shackleton HL, Singleton GF, Neale BE, Watton ME, Cullum S. Mini-Mental State Examination (MMSE) for the detection of dementia in clinically unevaluated people aged 65 and over in community and primary care populations. Cochrane Database of Systematic Reviews 2016, Issue 1. Art. No.: CD011145. DOI: 10.1002/14651858.CD011145.pub2</p>
                    <p>8. Custodio N, Duque L, Montesinos R, Alva-Diaz C, Mellado M, Slachevsky A. Systematic Review of the Diagnostic Validity of Brief Cognitive Screenings for Early Dementia Detection in Spanish-Speaking Adults in Latin America. Front Aging Neurosci. Sep 2020 4;12:270. doi: 10.3389/fnagi.2020.00270. PMID: 33101004; PMCID: PMC7500065.</p>
                    <p>9. Damasceno, Benito. “Critérios Para o Diagnóstico De Doença De Alzheimer.” Dement Neuropsychol (2011): n. pag. Print. </p>
                    <p>10. Domenech J P, Artigas P A. &quot;Dementia DSM-IV/ICD-10 or neurocognitive disorder DSM-V?.&quot; European Journal of Psychiatry 29.1 (2015) 45-50. </p>
                    <p>11. Eramudugolla R, Mortby M E, Sachdev P, Meslin C, Kumar R, Anstey K J. “Evaluation of a research diagnostic algorithm for DSM-5 neurocognitive disorders in a population-based cohort of older adults. Alzheimer’s Research &amp; Therapy, 2017. 9:15. DOI 10.1186/s13195- 017-0246-x. </p>
                    <p>12. Fornari L H Tieppo, Garcia L P, Hilbig A., Fernandez L L. As diversas faces da síndrome demencial: como diagnosticar clinicamente?. Scientia Médica (Porto Alegre)2010, n. 2, p. 185-193. </p>
                    <p>13. Furtner J, Prayer D. Neuroimaging in dementia. Wien Med Wochenschr. 2021 Sep;171(11-12):274-281. doi: 10.1007/s10354-021-00825-x. Epub 2021 Mar 3. PMID: 33660199; PMCID: PMC8397686. </p>
                    <p>14. Galvin JE, Sadowsky CH; NINCDS-ADRDA. Practical guidelines for the recognition and diagnosis of dementia. J Am Board Fam Med. May-Jun 2012; 25(3):367-82. doi: 10.3122/jabfm.2012.03.100181. PMID: 22570400. </p>
                    <p>15. Harrison JK, Stott DJ, McShane R, Noel-Storr AH, Swann-Price RS, Quinn TJ. Informant Questionnaire on Cognitive Decline in the Elderly (IQCODE) for the early diagnosis of dementia across a variety of healthcare settings. Cochrane Database Syst Rev. 2016 Nov 21;11(11):CD011333. doi: 10.1002/14651858.CD011333.pub2. Update in: Cochrane Database Syst Rev. 2021 Jul 18;7:CD011333. PMID: 27869298; PMCID: PMC6477966. </p>
                    <p>16. Ibarrola M L, Contrelas R M S, Ortiz A L S. Clasificación y diagnósticos actuales de las demências. Arch neurocién 2016 vol 21. </p>
                    <p>17. Iribarne C, Renner V, Pérez C, Guevara DL; Transtorno Del Ánimo y Demencia. Aspectos clínicos y estúdios complementários en el diagnóstico diferencial. REV. MED. CLIN. CONDES – fev 2020; 31(2) 150-162]. https://doi.org/10.1016/j.rmclc.2020.02.001 </p>
                    <p>18. Lee J, Ganguli M, Weerman A, et al. Diagnóstico de Consenso Clínico Online de Demência: Desenvolvimento e Validação. J Am Geriatr Soc . 2020;68 Suplemento 3(Suplemento 3):S54-S59. doi:10.1111/jgs.16736. </p>
                    <p>19. Lino, Valéria Teresa Saraiva et al. Adaptação transcultural da Escala de Independência em Atividades da Vida Diária (Escala de Katz). Cadernos de Saúde Pública [online]. 2008, v. 24, n. 1 [Acessado 26 março 2022] , pp. 103-112. Disponível em: &lt;https://doi.org/10.1590/S0102-311X2008000100010&gt;. Epub 11 Jan 2008. ISSN 1678- 4464. https://doi.org/10.1590/S0102-311X2008000100010. </p>
                    <p>20. Luna-Lario P, Azcárate-Jiménez L, Seijas-Gómez R, Tirapu-Ustárroz J. Propuesta de una bateria neuropsicologica de evaluacion cognitiva para detectar y discriminar deterioro cognitivo leve y demencias [Proposal for a neuropsychological cognitive evaluation battery for detecting and distinguishing between mild cognitive impairment and dementias]. Rev Neurol. 2015 Jun 16;60(12):553-61. Spanish. PMID: 26062828 </p>
                    <p>21. Melo, Simone Cecília de et al. Dementias in Brazil: increasing burden in the 2000–2016 period. Estimates from the Global Burden of Disease Study 2016. Arquivos de Neuro- Psiquiatria [online]. 2020, v. 78, n. 12 [Accessed 1 March 2022], pp. 762-771. Available from: &lt;https://doi.org/10.1590/0004-282X20200059&gt;. Epub 07 Dec 2020. ISSN 1678- 4227. https://doi.org/10.1590/0004-282X20200059. </p>
                    <p>22. Piovesana MCFS, Garcia FR, Carrasco KG, Tognola WA. Reassessment of the dementia diagnosis of Alzheimer&#39;s disease in patients enrolled on the cholinesterase inhibitors dispensation program. Dement Neuropsychol. 2012.Oct-Dec;6(4):270-275. doi: 10.1590/S1980-57642012DN06040012. PMID: 29213808; PMCID: PMC5619340. </p>
                    <p>23. Psiquiatria [online]. 2020, v. 78, n. 12 [Accessed 1 March 2022] , pp. 755-756. Available from: &lt;https://doi.org/10.1590/0004-282X20200191&gt;. Epub 14 Dec 2020. ISSN 1678-4227. https://doi.org/10.1590/0004-282X20200191. </p>
                    <p>24. Skrobot OA, Black SE, Chen C, DeCarli C, Erkinjuntti T, Ford GA, Kalaria RN, O&#39;Brien J, Pantoni L, Pasquier F, Roman GC, Wallin A, Sachdev P, Skoog I; VICCCS group, Ben-Shlomo Y, Passmore AP, Love S, Kehoe PG. Progress toward standardized diagnosis of vascular cognitive impairment: Guidelines from the Vascular Impairment of Cognition Classification Consensus Study. Alzheimers Dement. 2018 Mar;14(3):280-292. doi: 10.1016/j.jalz.2017.09.007. Epub 2017 Oct 19. PMID: 29055812. </p>
                    <p>25. Taylor-Rowan M, Nafisi S, Patel A, Burton JK, Quinn TJ. Informant-based screening tools for diagnosis of dementia, an overview of systematic reviews of test accuracy studies protocol. Syst Rev. 2020 Nov 26;9(1):271. doi: 10.1186/s13643-020-01530-3. PMID: 33243282; PMCID: PMC7694897. </p>
                    <p>26. Van der Schaar J, Visser LNC, Bouwman FH, Ket JCF, Scheltens P, Bredenoord AL, van der Flier WM. Considerations regarding a diagnosis of Alzheimer&#39;s disease before dementia: a systematic review. Alzheimers Res Ther. 2022 Feb 10;14(1):31. doi: 10.1186/s13195-022-00971-3. PMID: 35144684; PMCID: PMC8829985. </p>
                    <p>27. Zeilinger EL, Komenda S, Zrnic I, Franken F, Woditschka K. Informant-based assessment instruments for dementia and their measurement properties in persons with intellectual disability: systematic review protocol. BMJ Open. 2020 Dec 8;10(12):e040920. doi: 10.1136/bmjopen-2020-040920. PMID: 33293393; PMCID: PMC7725090.</p>
                </div>
            </div>
            
            <div className="row">
                <div className="col mt-5 text-center">
                    <button className="btn btn-default" onClick={goToInit}>Início</button>
                </div>

            </div>
        </ContainerPage>
    )
}

export default Bibliografia
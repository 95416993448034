
import ContainerPage from '../../components/ContainerPage'

import { useHistory } from 'react-router-dom'

function Apresentacao(){
    const history = useHistory()

    const next = (e) => {

        history.push('/init')
    }

    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Apresentação</strong></h3>

                    <form>
                        
                        <p>Este produto foi desenvolvido no âmbito do curso de mestrado profissional em ciências aplicadas a saúde da UNIVÁS.</p>
                        <p>Consiste em um aplicativo que tem como objetivo auxiliar profissionais da saúde primária no diagnóstico dos quadros demenciais.</p>
                        <p>Nele, há um roteiro diagnostico desde a anamnese com pacientes e familiares até os pontos mais importantes para elucidar este quadro.</p>
                        <p>Possui, ainda, recomendações de exames complementares uteis para elucidar diagnósticos etiológicos desta síndrome.</p>
                        <p>Espera-se que ajude esses profissionais de saúde nesta missão hercúlea.</p>

                        <p className='text-center'>
                            O autor
                        </p>
                        

                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Próximo</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default Apresentacao
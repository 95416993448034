
import ContainerPage from '../../components/ContainerPage'

import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useData } from '../../Context/DataContext'

function FluenciaVerbal(){
    const history = useHistory()
    const [dados, setDados] = useState({
        quantidadeAnimais: 0,
        quantidadeFrutas: 0,
        frase: 0
    })

    const data = useData()

    const next = (e) => {

        // data.setData({
        //     ...data,
        //     quantidadeAnimais: dados.quantidadeAnimais,
        //     quantidadeFrutas: dados.quantidadeFrutas,
        //     frase: dados.frase
        // })

        const newData = {
            ...(data.data),
            FluenciaVerbal: {
                ...dados
            }
        }

        data.setData(newData)

        history.push('/relogio')
    }

    const handleChange = (event) => {
        setDados({
            ...dados,
            [event.target.name]: event.target.value
        })
    }

    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Fluência Verbal</strong></h3>

                    <form>
                        
                        <p className='mt-2'>Para avaliação da fluência verbal solicitamos ao examinado que fale o máximo de nomes de animais em um prazo de 60 segundos. (Cada animal vale 01 ponto)</p>
                        <p>PONTUAÇÃO:</p>
                        <input type={"number"} name="quantidadeAnimais" onChange={handleChange} className='form-control' />
                        
                        <p className='mt-2'>Podemos pedir para o examinado falar o máximode nomes de frutas em um prazo de 60 segundos.(Cada fruta vale 01 ponto)</p>
                        <p>PONTUAÇÃO</p>
                        <input type={"number"} name="quantidadeFrutas" onChange={handleChange} className='form-control' />

                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Próximo</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default FluenciaVerbal
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import ContainerPage from '../../components/ContainerPage'

import { useData } from '../../Context/DataContext'

function Iqcode(){
    const data = useData()
    const [iqcode, setIqcode] = useState({
        respostas: []
    })

    const questoes = [
        {
            id: 1,
            titulo: "Lembrar de rostos de parentes e amigos"
        },
        {
            id: 2,
            titulo: "Lembrar dos nomes de parentes e amigos"
        },
        {
            id: 3,
            titulo: "Lembrar de fatos relacionados a parentes e amigos como, por exemplo, suas profissões, aniversários e endereços"
        },
        {
            id: 4,
            titulo: "Lembrar de acontecimentos recentes"
        },
        {
            id: 5,
            titulo: "Lembrar-se de conversas decorridos alguns dias"
        },
        {
            id: 6,
            titulo: "No meio de uma conversa, esquecer o que ele(a) queria dizer"
        },
        {
            id: 7,
            titulo: "Lembrar o próprio endereço e telefone"
        },
        {
            id: 8,
            titulo: "Saber o dia e o mês em que estamos"
        },
        {
            id: 9,
            titulo: "Lembrar onde as coisas são geralmente guardadas"
        },
        {
            id: 10,
            titulo: "Lembrar onde encontrar as coisas que foram guardadas em lugares diferentes daqueles em que costuma guardar"
        },
        {
            id: 11,
            titulo: "Adaptar-se a qualquer mudança no dia a dia"
        },
        {
            id: 12,
            titulo: "Saber utilizar aparelhos domésticos (liquidificador, ferro elétrico, fogão)"
        },
        {
            id: 13,
            titulo: "Aprender a utilizar um novo aparelho existente na casa"
        },
        {
            id: 14,
            titulo: "Aprender coisas novas em geral"
        },
        {
            id: 15,
            titulo: "Lembrar das coisas que aconteceram na juventude (por exemplo, em que trabalhou quando era jovem)"
        },
        {
            id: 16,
            titulo: "Lembrar de coisas que aprendeu quando era jovem"
        },
        {
            id: 17,
            titulo: "Entender o significado de palavras pouco utilizadas"
        },
        {
            id: 18,
            titulo: "Entender o que é escrito em revistas e jornais"
        },
        {
            id: 19,
            titulo: "Acompanhar histórias em livros ou em programas de televisão (por exemplo, acompanhar uma novela entendendo a história do início ao fim)"
        },
        {
            id: 20,
            titulo: "Escrever uma carta para amigos ou com fins profissionais"
        },
        {
            id: 21,
            titulo: "Conhecer importantes fatos históricos"
        },
        {
            id: 22,
            titulo: "Tomar decisões no dia-a-dia"
        },
        {
            id: 23,
            titulo: "Lidar com dinheiro para as compras"
        },
        {
            id: 24,
            titulo: "Lidar com assuntos financeiros (por exemplo: aposentadoria e conta bancária)"
        },
        {
            id: 25,
            titulo: "Lidar com outros cálculos do dia-a-dia (por exemplo: quantidade de comida a comprar, há quanto tempo não recebe visitas de parentes ou amigos)"
        },
        {
            id: 26,
            titulo: "Usar sua inteligência para compreender e pensar sobre o que está acontecendo"
        },
    ]
    const history = useHistory()

    useEffect(() => {
        const respostas = {}
        questoes.forEach(questao => {
            respostas[questao.id] = 1
        })

        setIqcode({
            respostas
        })
    }, [])

    const handleInput = (evt) => {
        const field = evt.target.name
        const value = evt.target.value

        setIqcode({
            ...iqcode,
            respostas:{
                ...iqcode.respostas,
                [field]: value
            }
        })
    }

    const next = (e) => {
        const keys = Object.keys(iqcode.respostas)
        let score = 0

        keys.forEach(key => {
            score += parseInt(iqcode.respostas[key])
        })

        score = Math.round(score / 26)

        const newData = {
            ...(data.data),
            iqcode: score
        }

        data.setData(newData)

        // data.setData({
        //     ...data.data,
        //     iqcode: score
        // })

        history.push("katz")
    }
    // console.log(iqcode)

    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Exame IQCODE - BR</strong></h3>

                    <p>
                        “ESSE QUESTIONÁRIO SERÁ APLICADO EM UM INFORMANTE PRÓXIMO DO PACIENTE (PESSOA DE INTIMO CONVÍVIO)”.
                    </p>

                    <p>
                        Instruções de como começar o IQCODE - BR
                    </p>

                    <p>
                        GOSTARIA QUE O SR. RECORDASSE O ESTADO EM QUE O SR. X {data.data.paciente.nome}
                        SE ENCONTRAVA A 10 ANOS E O COMPARASSE COM O ESTADO ATUAL. DESCREVEMOS
                        ABAIXO SITUAÇÕES EM QUE ELE TENHA DE USAR A MEMÓRIA OU O RACIOCÍNIO E EU
                        GOSTARIA QUE O SR. DISSESSE SE, NESTE ASPECTO, ELE(A) MELHOROU, PIOROU, OU
                        PERMANECEU SEM ALTERAÇÕES NOS ULTIMOS 10 ANOS. É MUITO IMPORTANTE
                        COMPARAR O DESEMPENHO ATUAL DO SR. X COM O DESEMPENHO DELE HÁ 10 ANOS
                        ATRAS. DESTE MODO, SE HÁ DEZ ANOS ELE(A) SEMPRE SE ESQUECIA ONDE HAVIA
                        DEIXADO AS COISAS E ISSO AINDA ACONTECE, ENTÃO ISTO SERÁ CONSIDERADO
                        COMO “POUCO MUDANÇA”. ASSIM, DIGA-ME: “COMPARADO COM O ESTADO HÁ DEZ
                        ANOS, COMO SE ENCONTRA O SR. X NA ´PRESENTE DATA?    
                    </p>

                    <form>
                        {
                            questoes.map(questao => (
                                <div className="form-group mt-3 mb-3" key={questao.id}>
                                    <label>{questao.id} - {questao.titulo}</label>
                                    <select 
                                        className="form-control" 
                                        value={iqcode.dificuldadeCoisas} 
                                        onChange={handleInput} 
                                        name={questao.id}>
                                        <option value={1}>Melhor</option>
                                        <option value={2}>Um pouco melhor</option>
                                        <option value={3}>Pouca mudança</option>
                                        <option value={4}>Um pouco pior</option>
                                        <option value={5}>Pior</option>
                                    </select>
                                </div>
                            ))
                        }
                        
                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Próximo</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default Iqcode
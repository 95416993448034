
import ContainerPage from '../../components/ContainerPage'

import { useData } from '../../Context/DataContext'
import { useHistory } from 'react-router-dom'

function ResultadoFinal(){
    const data = useData()
    const history = useHistory()
    let anormalidade = false

    const next = (e) => {
        data.setData({
            
        })
        history.push('/')
    }

    const dados = data.data

    if(parseInt(dados.paciente.escolaridade) >= 6 && dados.minimental <= 26){
        anormalidade = true
    }

    if(parseInt(dados.paciente.escolaridade) == 0 && dados.minimental <= 21){
        anormalidade = true
    }

    if(parseInt(dados.relogio) <= 5){
        anormalidade = true
    }

    if(parseInt(dados.paciente.escolaridade) == 0 && parseInt(dados.FluenciaVerbal.quantidadeAnimais) < 9){
        anormalidade = true
    }

    if(parseInt(dados.paciente.escolaridade) != 0 && parseInt(dados.FluenciaVerbal.quantidadeAnimais) < 13){
        anormalidade = true
    }

    if(parseInt(dados.quantidadememoria3) < 6){
        anormalidade = true
    }

    if(parseInt(dados.quantidadememoria) < 5){
        anormalidade = true
    }

    if(parseInt(dados.iqcode) >= 4){
        anormalidade = true 
    }

    if(dados.katz <= 2){
        anormalidade = true
    }

    console.log(data.data)

    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Resultado Final</strong></h3>

                    <form>
                        <p>
                            {
                                anormalidade ? "Há probabilidade clínica de algum quadro demencial" :
                                "Não há probabilidade clínica de algum quadro demencial"
                            }
                        </p>
                        
                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Sair</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default ResultadoFinal
import { useHistory } from 'react-router'
import mpcas from '../../assets/MPCAS_Univas.png'
import ContainerPage from '../../components/ContainerPage'

function Home(){
    const history = useHistory()
    const goToInit = () => history.push("/apresentacao")
    const goToBiblio = () => history.push("/biblio")
    return (
        <ContainerPage>
            <div className="row">
                <div className="col text-center mt-5 mb-5">
                    <p>Gustavo Antonio Mendes</p>
                    <p>Prof. Dr. Taylor Brandão Schneider</p>
                    <p>Prof. Dr. Geraldo Magela Salomé</p>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-10">
                    <img className="img-fluid" alt="MPCAS | Univás" src={mpcas}/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-5 text-center">
                    <button className="btn btn-default" onClick={goToInit}>Acessar</button>
                </div>
                <div className="col-12 mt-5 text-center">
                    <button className="btn btn-default" onClick={goToBiblio}>Referências</button>
                </div>
            </div>
        </ContainerPage>
    )
}

export default Home
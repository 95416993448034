import style from './style.module.css'

function ContainerPage({ children }){
    return (
        <div className={style.container + " container-xl"}>
            { children }
        </div>
    )
}

export default ContainerPage
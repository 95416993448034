
import ContainerPage from '../../components/ContainerPage'

import { useHistory } from 'react-router-dom'

function Exames(){
    const history = useHistory()

    const next = (e) => {

        history.push('/resultadoFinal')
    }

    return (
        <ContainerPage>
            <div className="row">
                <div className="col mt-1 mb-5">
                    <h3 className="text-center mb-4"><strong>Exames a serem solicitados</strong></h3>

                    <form>
                        <p>
                            Hemograma, Creatinina, TSH, Albumina, TGO, TGP, Vit. B12, VDRL, Uréia, TGO, TGP, Glicemia, Ácido Fólico, Ionograma.
                        </p>
                        <p>
                            Método de imagens TCC ou RNM de encéfalo
                        </p>
                        <p>
                            Para pacientes com menos de 60 anos, deve-se realizar teste de HIV.
                        </p>
                        <div className="form-group text-center mt-3">
                            <button className="btn btn-default" type="button" onClick={next}>Próximo</button>
                        </div>
                    </form>
                </div>
            </div>
        </ContainerPage>
    )
}

export default Exames